
// Crear Tarea
.titulo{

	h3{
		margin-left: 15%;
		margin-bottom: 30px;
		&.go-back{
			margin-left: 0;
		}
		span{
			font-weight: bold;
			font-size: 27px;
		}
	}
}
.capitalize{
	text-transform: capitalize;
}
.tabla_resumen_areas {
	.listado_tiempos td:first-child{
		font-weight: 600;
	}
	.badge {
    padding: 5px 7px;
    font-size: 1.1em;
    width: 70%;
	}
	    padding: 20px 80px;
	    margin: 0 auto;
	.encabezado {
		    display: inline-flex;
				font-weight: 600;
				font-size: 1.0em;
				span{
					padding: 10px 20px;
					border: 0.5px solid black;
				}
				.titulo_1{
					padding: 10px 20px;
					font-size: 1.2em;
					border: 0.5px solid black;
				}
	}
	.contenido_resumen {
		 display: inline-flex;
		span{
			padding: 20px 30px;
		}
	}
}
.descripcion_tarea{
	word-break:break-all;
}
.link_paginador{
	text-align: center;
}
#estados_trafico3{
      height: 35px;
}
.tarea{
	padding: 15px;
	box-shadow: none;
	&.ver_tarea{
		position: relative;
		border-radius: 3px;
		background: #ffffff;
		border-top: 3px solid #d2d6de;
		margin-bottom: 20px;
		width: 100%;
		box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
	}
	.multiselect{
		border-radius: 0;
		min-height: 28px;
		height: 28px;
		padding: 0;
		input{
			height: 25px;
		}
		.multiselect__tags{
			min-height: 28px;
			height: 28px;
			padding-top:0;
			border-radius: 0;
		}
		.multiselect__select{
			min-height: 28px;
			height: 28px;
			&:before{
				color:$azul-claro;
				border-top-color: $azul-claro;
			}
		}
	}
	.input-group-addon{
		padding: 4px 10px;
	}
	.form-control{
		border-radius: 0;
	}
	input{
		height: 28px;
	}
	.input-group.date{
		.datepicker {
			padding: 0 12px;
			height: 28px;
			position: relative;
			input{
				width: 100%;
				text-align: center;
				margin-left:0;
				height: 100%;
				margin-top: 0;
				border: 0;
			}
			&:before{
				content: " ";
				display: block;
				position: absolute;
				top: 10px;
				right: 10px;
				border-style: solid;
			    border-width: 5px 5px 0;
			    border-color: $azul-claro transparent transparent;
			}
		}
	}
	.prioridad{
		.multiselect__element{
			color:#fff;
			&:nth-child(1){
				background-color: $estado-alta;
			}
			&:nth-child(2){
				background-color: $estado-media;
			}
			&:nth-child(3){
				background-color: $estado-baja;
			}
		}
	}
	.estados{
		/* .multiselect__element{
			color:#fff;
			&:nth-child(1){
				background-color: $estado-ok;
			}
			&:nth-child(2){
				background-color: $estado-realizado;
			}
			&:nth-child(3){
				background-color: $estado-programado;
			}
			&:nth-child(4){
				background-color: $estado-at-cuentas;
			}
			&:nth-child(5){
				background-color: $estado-at-cuentas;
			}
			&:nth-child(6){
				background-color: $estado-espera;
			}
			&:nth-child(7){
				background-color: $estado-pendiente;
			}
		} */
	}
}
.label-estado{
	display: block;
	padding: 3px 5px;
	height: 28px;
	font-size: 14px;
	width: 100%;
	border-radius: 0;
	line-height: 1.5;
	font-weight: 300;
}
// Tabla Foro
.datatable-foro{
	thead th{
		text-align: center;
		background-color: $azul-profundo;
		color:$font-blanco;
	}
	td{
		text-align: center;
		color:$font-gris;
	}
}
.trafico{
	#tabla_tareas_wrapper{
		margin-bottom: 120px;
		.datepicker{
			width: 100%;
			input{
				width: 100%;
			}
		}
	}
}
#tabla_tareas_wrapper{

	label{
		width: 100%;
		input{
			max-width: 290px;
		}
	}
	.filtros{
		div{
			margin-bottom: 5px;
			&:not(:last-child){
				border-right: 1px solid $borde-gris;
			}
		}
		#estados2{
			width: 100%;
		}
		.drop{
			display: inline-block;
			position: relative;
			&:before{
				width: 0;
				height: 0;
				display: block;
				border-left: 4px solid transparent;
				border-right: 4px solid transparent;
				border-top: 4px solid $azul-claro;
				position: absolute;
				top: 40%;
				right: 5px;
				content: " ";
				z-index: 98;
			}
			.datepicker {
				margin: 0;
				padding: 0;
			}
			@media screen and (max-width: 768px) {
				display: block;
			}
		}

		select{
			padding: 0px 10px;
			-webkit-appearance: none;
		    -moz-appearance: none;
		    appearance: none;

		}
	}
	.row {
	  display: -webkit-box;
	  display: -webkit-flex;
	  display: -ms-flexbox;
	  display:         flex;
	  flex-wrap: wrap;
	}
	.row > [class*='col-'] {
	  display: flex;
	  flex-direction: column;
		justify-content: center;
	}
}

.footer_tarea{
	position: absolute;
	width: 100%;
	bottom: 0;
}
.desc-ot{
	padding: 0 0 25px;
	margin-bottom: 40px;
	strong{
		color:$azul-claro;
	}
	ul{
		padding: 0;
		margin: 0;
		list-style: none;
		li{
			margin: 7px 0;
		}
	}
}
.with-border{
	border-bottom: 1px solid $borde-gris;
}

.user_created_and_area{
	position:relative;
	float:right;
	margin-right:18px;
	margin-bottom: 20px;
	a{
		text-decoration: none;
		font-size: 18px;
		font-weight: bold;
		span{
			font-size: 15px;
			font-weight: normal;
			color: black;
			&:hover{
				color: $azul-claro;
			}
		}
	}
	@media screen and (max-width: 992px) {
		display: block;
		float: none;
		margin: 15px auto;
		max-width: 220px;
	}

}

//Ver tarea
.btn-back{
	margin-right: 2%;
	margin-left: 3%;
	vertical-align: top;
}
.ver_tarea,
.ver_solicitud_seccion{
	padding: 70px;
	img{
		max-width: 100%;
	}
	.contenedor_fecha{
		display: flex;
		.input-group-addon{
			width: 50px;
		}
	}
	.alert_ups{
		background-color:#E94800 !important;
		text-align: center;
		font-size:  16px;
		font-weight: bold;
	}
	.comentario_box{
		box-shadow:none;
		.caja_comentarios{
			margin-top: 20px;
			p{
				word-wrap: break-word;
				white-space: pre-wrap;
			}
		}
		.comentario_box_container{
			display: flex;
			.img_comentario_src{
				width:65px;
			}
			.info_comentarios{
				display:flex;
				flex-direction:column;
				margin-left: 10px;
				span{
					color:#a7a7a7;
				}
				.comentario_created{
					margin-top: 5px;
				}
			}
		}
	}
	.text_alert{
		color: #E94800;
		text-align: center;
	}
	.estado_comentario{
		position: absolute;
		right: 25px;

		.estado_comentario_circle{
			 width: 15px;
		    height: 15px;
		    position: absolute;
		    border-radius: 7px;
		    margin-left: 8px;
		    margin-top: 3px;
		}
	}
	.ruta_server{
		margin-bottom: 35px;
		p{
			word-wrap: break-word;
		}
	}

	.columna2{
		padding-bottom: 30px;
	}
	.desc-ot{
		padding-bottom: 15px;
	}
	textarea{
		min-height: 200px;
	}
	label{
		color:$azul-claro;
	}
	.prioridad_btn{
		display: block;
		width: 100px;
		color: white;
		border:  none;
		&:disabled{
		 color: white;
		 opacity: 1;
		}
	}
	.multiselect__select{
		min-height: 33px !important;
	}
	.multiselect__tags{
		height: 35px !important;
	}
	.multiselect__input{
		height: 30px !important;
	}
	.multiselect__single{
		padding-top: 5px !important;
	}
	.tiempo_estimado{
		height: 35px;
		width: 90px;
	}
}

#enlaces_externos{
	line-height: 30px;
}

.descripcion_tarea_perfil{
	margin-bottom: 30px;
}



//Tareas Nuevas
.seccion_mas_tareas{
	.form_tarea_nueva{
		text-align: left;
		margin-bottom: 2%;
        padding-bottom: 6%;
		&:nth-child(even){
			background-color: white;
		    padding: 28px;
	    }
	    &:nth-child(odd){
			background-color: rgba(181, 181, 181, 0.30);
		    padding: 28px;
	    }


		.division_tareas_nuevas{
			margin-top: 2%;
		}
		.titulo_tareas_nuevas{
			margin-top: 3%;
			margin-bottom: 4%;
			text-align: center;
		}
	}
	.boton_agregar_tareas{
		margin-top: 35px;
	}
}
