
// Fonts
@import url(https://fonts.googleapis.com/css?family=Raleway:300,400,600);
@import url(https://fonts.googleapis.com/css?family=Ubuntu:400,400i,700);
@font-face {
  font-family: 'iconforo';
  src: url('../fonts/icon-foro.woff'); /* IE9 Compat Modes */
  src: url('../fonts/icon-foro.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       // url('../fonts/icon-foro.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/icon-foro.woff') format('woff'), /* Pretty Modern Browsers */
       url('../fonts/icon-foro.ttf')  format('truetype'), /* Safari, Android, iOS */
       url('../fonts/icon-foro.svg#svgFontName') format('svg'); /* Legacy iOS */
}


// Variables
@import "variables";

// Estilos Generales 
@import "estilos_generales";

//Ionicons
@import "node_modules/ionicons/dist/scss/ionicons.scss";

//Font awesome
@import "node_modules/font-awesome/scss/font-awesome.scss";

//transiciones tablas
@import "transitions";


//Visualización OT
@import "./OT/vistas_ots.scss";

//Estilos del dashboard menu, sidebar, header ...
@import "dashboard";

//Estilos listar areas con usuarios e informació de cada uno y las horas
@import "listar_areas";

//Estilos perfil
@import "perfil";

//Visualización OT
@import "./usuarios/listar_usuarios.scss";

//Visualizacion Tarea
@import "tarea";

//Visualizacion Tarea
@import "notificaciones";

//Estilos ver Requerimiento
@import "ver_solicitud";
