// Botones
.btn{
	padding: 4px 12px;
}
.btn-primary{
	background-color: $azul-profundo;
}
.btn-info{
	background-color: $azul-claro;
}

// Fuentes
.font-info{
	color:$azul-claro;
}
.font-primary{
	color: $azul-profundo;
}

// Estados Datatable
.estado-1-1{
	background-color: $estado-ok;
}
.estado-1-2{
	background-color: $estado-realizado;
}
.estado-1-3{
	background-color: $estado-programado;
}
.estado-1-4{
	background-color: $estado-at-cuentas;
}
.estado-1-5{
	background-color: $estado-at-cuentas;
}
.estado-1-6{
	background-color: $estado-espera;
}
.estado-1-7{
	background-color: $estado-pendiente;
}
.estado-1-20{
	background-color: $estado-entregado;
}
.estado-2-8{
	background-color: $estado-ongoing;
}
.estado-2-9{
	background-color: $estado-en-espera;
}
.estado-2-10{
	background-color: $estado-terminado;
}
.estado-3-11{
	background-color: $estado-alta;
}
.estado-3-12{
	background-color: $estado-media;
}
.estado-3-13{
	background-color: $estado-baja;
}
.estado-4-14{
	background-color: $estado-programado;
}
.estado-4-15{
	background-color: $estado-revisar;
}
.estado-4-16{
	background-color: $estado-entregado;
}
.estado-4-17{
	background-color: $estado-incumplimiento;
}
.estado-4-18{
	background-color: $estado-pendiente-entrega;
}
.estado-4-19{
	background-color: $estado-programar;
}
.estado-5-21{
	background-color: $estado-pendiente;
}
.estado-5-22{
	background-color: $estado-ok;
}
.estado-6-23{
	background-color: $estado-en-espera;
}
.estado-6-24{
	background-color: $estado-ok;
}
.estado-6-25{
	background-color: $estado-at-cliente;
}
.estado-6-26{
	background-color: $estado_invalido_requerimiento;
}

.estado-6-27{
	background-color: $estado_finalizado_requerimiento;
}

// Bordes
.box {
	.border-right{
		border-right: 1px solid $borde-gris;
	}
	.border-left {
	  	border-left: 1px solid $borde-gris;
	}
}

// Multiselect

.multiselect__option--highlight{
	background-color: $azul-claro !important;
}
.multiselect__option--selected,
.multiselect__option--selected.multiselect__option--highlight{
	background-color: $azul-profundo !important;
	color:#fff !important;
}
a:hover, a:focus {
text-decoration: none;
}
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.form-group{
	margin-bottom: 20px;
}
.form-group.has-error .help-block{
	position: absolute;
	margin-top: 0;
}
.titulos_foro{
	h3{
		margin-left: 2%;
		margin-bottom: 30px;
		span{
			font-weight: bold;
			font-size: 27px;
		}
	}
}
.select_custom{
	height: 33px !important;
}
/* Datapicker */
.datepicker{
	position: relative;
	z-index: 100;
}

.same-height{
    min-height: 80px;
  }

.permisos{
	.alerta{

		font-size: 17px;
		margin-bottom: 35px;
		margin-top: 28px;
        margin-left: 30px;
	}
	.select_rol{
		width: 200px;
		margin-left: 12px;
	}
	.form-permisos{
	    display: flex;
	    justify-content: flex-start;
	    flex-wrap: wrap;
	    padding-left:2%;
	    padding-right:2%;
	}
	.box-header{
		display: flex;
	}
	.box-title{
		padding-left:1%;
		margin-bottom:20px;
	}
	.permisos_check{
		width: 25%;
		height: 30px;
		margin-top: 0px;
	}
	.box-footer{
		text-align: center;
		.guardar_roles{
			width: 130px;
		    font-size: 17px;
		    margin-top: 5px;
		    margin-bottom: 5px;
	    }
	}

}
// Asterisco rojo en formlarios
label sup{
	color: #ff0000;
}

//Tipos compra formulario
.tipos_compra_form1{
    padding-left: 30px;
    padding-right: 30px;
}

.tipos_compra_form2{
	border-left: 1px solid #eae9e9;
    padding-left: 30px;
    padding-right: 30px;
	padding-bottom: 20px;
}

//Selector de fecha trafico
.select_fecha_trafico1{
	.datepicker{
		.calendar{
			position: absolute;
			z-index: 9999;
		}
	}
}

.select_fecha_trafico2{
	position: absolute;
	z-index: 1;
}
