.Formulario_OT{
  .padding_ots{
        padding: 5%;
  }
  .border-right{
    border-right: 1px solid $borde-gris;
  }
  .border-left {
      border-left: 1px solid $borde-gris;
  }

  .form-group{
     color: #3c3939;
  }

  .campo_azul {
    color: white;
    margin-top: 12px;
    text-align: center;
    font-size: 20px;
    background: #15baee;
}
.campo{
  color: white;
  margin-top: 12px;
  text-align: center;
  font-size: 20px;
}
.rojo{
  background: #e21e00;
}
.verde{
  background: #3b9600;
}
.amarillo{
  background: #eaac1c ;
}

.boton_foro.error{
  background: #da0724;
  font-style: italic;
}
.boton_foro.add_req{
   background: #00ca78;
   width: 146px;
   font-size: 12px;
}

::-webkit-input-placeholder {  font-style: italic; color:#9a9a9a;  } /* WebKit */
:-moz-placeholder {  font-style: italic; color:#9a9a9a;  } /* Firefox 18- */
::-moz-placeholder {  font-style: italic; color:#9a9a9a; } /* Firefox 19+ */
:-ms-input-placeholder {  font-style: italic; color:#9a9a9a; } /* IE 10+ */

.titulo_interna_ot{
 color: #626262;
 font-weight: 600;
}


#seccion_compra{
.disabled{
  border: 0px;
      margin-bottom: 10px;
  background-color: #8d8d8d !important;
}
}
.formulario_ot_panel{
  border: 1px solid #d8d8d8;
  .ot_visualizar_observaciones{
    word-break: break-all;
  }
}
.nav-tabs-custom {
    margin-bottom: -1px;
}
.nav-tabs-custom > .nav-tabs > li.active > a {
    border-left-color: #d8d8d8;
    border-right-color: #d8d8d8;
}
.tab-content {
    border: 1px #d8d8d8 solid;
    padding: 40px;
}
.mensaje_info{
      padding: 10px 10px;
  background:#15baee;
  color:white;
}
.multiselect{
  border-radius: 0;
  min-height: 28px;
  height: 28px;
  font-style: italic;
  input{
    height: 22px;
  }
  .multiselect__tags{
    min-height: 28px;
    height: 28px;
    padding-top:2px;
    border-radius: 0;
  }
  .multiselect__select{
    min-height: 28px;
    height: 28px;
    &:before{
      color:$azul-claro;
      border-top-color: $azul-claro;
    }
  }
}

.detalle_horas{
  margin-top: 10px;
}

}


.separador{
  height: 1px;
  border: 0px;
  width: 100%;
  background: #dddddd ;
}
.letra_mediana{
  font-size: 16px;
}
.span_titulo1{
   font-size: 28px;
   font-weight: 900;
   color: #003a81;
}
.span_titulo2{
   font-size: 28px;
   font-weight: 600;
   color: #15baee;

}
.span_descripcion1{
   font-size: 20px;
   font-weight: 300;
   color: #003a81;
   margin-right: 5px;
}
.span_descripcion2{
   font-size: 20px;
   font-weight: 300;
   color: #15baee;
}

.formulario{
label {
  display: inline-block;
  cursor: pointer;
  position: relative;
  padding-left: 25px;
  margin-right: 15px;
  font-size: 13px;
}
input[type=radio] {
  display: none;
}
label:before {
  content: "";
  display: inline-block;

  width: 16px;
  height: 16px;

  margin-right: 10px;
  position: absolute;
  left: 0;
  bottom: 1px;
  border-radius: 16px;
  background-color: #fff;
  border: 1px #cccccc solid;
  //box-shadow: inset 0px 2px 3px 0px rgba(0, 0, 0, .3), 0px 1px 0px 0px rgba(255, 255, 255, .8);
}

.radio label:before {
  border-radius: 8px;
}

input[type=radio]:checked + label:before {
    content: "\2022";
    color: #15baee;
    font-size: 30px;
    margin-top: -5px;

    text-align: center;
    line-height: 13px;
    padding-left: 1px;
}
}

.nav-tabs-custom > .nav-tabs > li.active {
    border-top-color: #15baee;
}

.dates{
  .form-group{
    &:last-child{
     .datepicker{
      position: initial;
      z-index: 5;
      input{
        position: inherit;
        z-index: 5;
      }
     }
    }
  }
}

.input-group.date{

  .datepicker{
    input{
      width: 107%;
      text-align: center;
      margin-left: -4%;
      height: 141%;
      margin-top: -4%;
      border: 0;
        /* AB - 23-02-2017 */
      width: 100%;
      margin-left: 0;
      margin-top: 0;
      height: 100%;

    }

  }


}
.titulo_seccion_ot{
  margin-bottom: 25px;
}
#formulario_ot_actualizacion{
  .titulo_interna_ot{
   color: $azul-profundo;
   margin-top: 0;
  }
  .campo_fecha{
     font-weight: 600;
  }
  .listado_requerimientos_resumen {
    display: inline-block;
    padding: 20px 15px;
    width: 49%;
    .ot_visualizar_descripcion{
      word-break: break-word;
    }
}

}
.modal_personalizado{
  .close {
    font-size: 43px;
    margin-right: 20px;
    color: $azul-claro;
    text-shadow: 0 1px 0 $azul-claro;
}
.boton_foro:hover{
  color: white;
}

 .modal-content {
    background: #003a81;
 }

.contenido_modal {
    padding: 50px;
    text-align: center;
    color:white;
}
.desc_modal {
    font-size: 1.5em;
    padding: 20px 21%;
}

}

.form-control {
    height: 30px ;
    border-radius:0px;
}
textarea.form-control {
    height: auto;
}
.boton_foro {
  border-radius: 0px;
  font-size: 14px;
}
.boton_foro.succes{
   background: #3b9600;
   @media screen and (max-width: 1180px) {
    white-space: pre-line;
  }
}
.boton_foro.succes:hover{
   color:white;
}
#encabezado_ot{
  .form-group {
    padding: 12px 0px 46px 0px;
  }
  /*AB - 23-02-2017 */
  .help-block{
    line-height: 15px;
    position: absolute;
  }

  .same-height{
    min-height: 80px;
    margin-top: 10px;
  }
  .form-control{
    padding: 2px 12px;
  }
  .form-group{
    padding: 0;
  }
}
.required sup {
    color: #ff0000;
    font-size: 13px;
    vertical-align: text-bottom;
}
sup {
    top: -.5em;
}
.select-error{
  border: 1px solid red;
  /*  border-radius: 6px;*/
}
sub, sup {
    font-size: 75%;
    line-height: 0;
    position: relative;
    vertical-align: baseline;
}
.nopadding {
   padding: 0 !important;
   margin: 0 !important;
}
@media screen and (min-width: 1620px) {
.boton_foro.error{
  padding: 5px 28px;
}

}
@media screen and (max-width: 1360px) {
  .padding_ots{
        padding: 2%;
  }

}

@media screen and (max-width: 768px) {
  .nopadding {
   padding: auto !important;
   margin: auto !important;
}
#encabezado_ot .form-group {
    padding: 0px 0px 0px 0px;
}

}

.listar_ot_descripcion{
  word-break: inherit !important;
}
.btn_accion {
    display: inline-flex;
    width: 50%;
    color: white;
    height: 28px;
    border: 1px solid white;
    text-align: center;
    vertical-align: middle;
    i{
      text-align: center;
      margin: 21%;
      vertical-align: middle;
    }
}

.ot_validacion_guardar{
  .row{
    width: 90%;
    margin: 0 auto;
    padding: 2%;
    margin-bottom: 2%;

  }
}

.checkbox_ots{
  .checkbox {
      padding-left: 35%;
      margin-top: -20% !important;
      label:before{
        width: 25px;
        height: 25px;
      }
      label:after {
        font-size: 17px;
    }
  }
.styled{
  width: 20px;
}

}
