
// Body
$body-bg: #f5f8fa;

// Borders
$laravel-border-color: darken($body-bg, 10%);
$list-group-border: $laravel-border-color;
$navbar-default-border: $laravel-border-color;
$panel-default-border: $laravel-border-color;
$panel-inner-border: $laravel-border-color;

// Brands
$brand-primary: #3097D1;
$brand-info: #8eb4cb;
$brand-success: #2ab27b;
$brand-warning: #cbb956;
$brand-danger:  #bf5329;

// Typography
$font-family-sans-serif: "Roboto", Helvetica, Arial, sans-serif;
$font-size-base: 14px;
$line-height-base: 1.6;
$text-color: #636b6f;

// Navbar
$navbar-default-bg: #fff;

// Buttons
$btn-default-color: $text-color;

// Inputs
$input-border: lighten($text-color, 40%);
$input-border-focus: lighten($brand-primary, 25%);
$input-color-placeholder: lighten($text-color, 30%);

// Panels
$panel-default-heading-bg: #fff;

// Label Estados - Colors
$estado-ok:#61B100;
$estado-entregado:#C1AD16;
$estado-realizado:#FF9A00;
$estado-at-cuentas:#BF1112;
$estado-at-area:#720506;
$estado-espera:#9800C5;
$estado-pendiente:#BABABA;
$estado-programado:#15BAEE;
$estado-revision:#2347BB;

$estado-ongoing:#0B5394;
$estado-en-espera:#674EA7;
$estado-terminado:#6AA84F;

$estado-alta:#FF1A1A;
$estado-media:#FABC00;
$estado-baja:#56C716;

// $estado-programado-2:#FF1A1A;
$estado-revisar:#FABC00;
// $estado-entregado-2:#56C716;
$estado-incumplimiento:#FF1A1A;
$estado-pendiente-entrega:#FABC00;
$estado-programar:#56C716;

// $estado-pendiente-2:#FF1A1A;
// $estado-ok-2:#FABC00;

// $estado-espera:#FABC00;
$estado-at-cliente:#FABC00;
$estado-archivado:#56C716;

//Estados Requerimientos
$estado_finalizado_requerimiento:#36b715;
$estado_invalido_requerimiento:red;

// Colores
$azul-profundo:#002E60;
$azul-claro:#15baee;
$font-blanco:#fff;
$font-gris:#6F6F6F;

$borde-gris:#d8d8d8;
